/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Tamil font import */
@font-face {
  font-family: 'Latha';
  src: local('Latha');
}

@layer base {
  html {
    @apply antialiased;
  }
  
  body {
    @apply bg-gray-50;
  }
}

@layer components {
  .tn-button {
    @apply px-4 py-2 bg-tn-primary text-white rounded-md hover:bg-blue-800 
           transition-colors duration-200 focus:outline-none focus:ring-2 
           focus:ring-offset-2 focus:ring-tn-primary;
  }
  
  .tn-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
}